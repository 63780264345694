import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { getCategories, deleteCategory } from '../../API'

const CategoryList = () => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories()
                setCategories(data)
            } catch (error) {
                console.error('Error fetching categories:', error)
            }
        }

        fetchCategories()
    }, [])

    const handleDelete = async (id) => {
        try {
            await deleteCategory(id)
            setCategories(categories.filter(category => category._id !== id))
        } catch (error) {
            console.error('Error deleting category:', error)
        }
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Categories
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/categories/create"
                sx={{ mb: 3 }}
            >
                Create Category
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Icon Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.map((category) => (
                            <TableRow key={category._id}>
                                <TableCell>{category.name}</TableCell>
                                <TableCell>{category.iconName}</TableCell>
                                <TableCell>
                                    <Button
                                        component={Link}
                                        to={`/categories/edit/${category._id}`}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => handleDelete(category._id)}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        sx={{ ml: 2 }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default CategoryList
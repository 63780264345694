import React, { useState, useEffect } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { getEvents, deleteEvent } from '../../API'

const EventList = () => {
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchEvents = async () => {
        try {
            const response = await getEvents()
            if (Array.isArray(response)) {
                setEvents(response)
            } else {
                console.error('Expected an array, received:', response)
                setEvents([])
            }
        } catch (error) {
            console.error('Error fetching events:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    const handleDelete = async (id) => {
        try {
            await deleteEvent(id)
            setEvents(events.filter(event => event._id !== id))
        } catch (error) {
            console.error('Error deleting event:', error)
        }
    }

    const renderPreview = (mediaItem) => {
        if (mediaItem.type === 'image') {
            return <img src={mediaItem.url} alt="media preview" style={{ maxWidth: '50px', maxHeight: '50px' }} />
        } else if (mediaItem.type === 'video') {
            return <video src={mediaItem.url} style={{ maxWidth: '50px', maxHeight: '50px' }} controls />
        } else if (mediaItem.type === 'animated') {
            return <img src={mediaItem.url} alt="animated preview" style={{ maxWidth: '50px', maxHeight: '50px' }} />
        } else {
            return <span>Unknown Media Type</span>
        }
    }

    if (loading) {
        return <Typography variant="h6">Loading events...</Typography>
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Events
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/events/create"
                sx={{ mb: 3 }}
            >
                Create Event
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Media</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(events) && events.length > 0 ? (
                            events.map((event) => (
                                <TableRow key={event._id}>
                                    <TableCell>{renderPreview(event.primaryMedia)} {event.media.map((mediaItem) => renderPreview(mediaItem))}</TableCell>
                                    <TableCell>{event.title}</TableCell>
                                    <TableCell>{event.description}</TableCell>
                                    <TableCell>{event.category.name}</TableCell>
                                    <TableCell>{new Date(event.startDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{new Date(event.endDate).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={`/events/edit/${event._id}`}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(event._id)}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{ ml: 2 }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No events found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default EventList
import React, { useState } from 'react'
import { Modal, Box, TextField, Button, Typography, List, ListItem, ListItemText } from '@mui/material'
import axios from 'axios'
import { memberSearch } from '../API'
import Loader from './Loader'

const MemberSearchModal = ({ open, onClose, onSelect }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSearch = async () => {
    try 
    {
      setLoading(true)
      let response = await memberSearch(query)
      setResults(response)
    } 
    catch (error) 
    {
      console.error('Error performing search', error)
    }
    finally 
    {
      setLoading(false)
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" component="h2">Member Search</Typography>
        <TextField
          label="Search Query"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>Search</Button>
        <List>
          {results.map(member => (
            <ListItem button key={member.id} onClick={() => onSelect(member)}>
              <ListItemText primary={`${member.firstName} ${member.lastName} ${member.lastName2}`} />
            </ListItem>
          ))}
        </List>
        <Loader open={loading} />
      </Box>
    </Modal>
  )
}

export default MemberSearchModal

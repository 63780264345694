import React, { useState, useEffect } from 'react'
import { Container, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { addDays, setHours, setMinutes, setSeconds, startOfDay } from 'date-fns'
import { getComps, deleteComp, compsExport } from '../API'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CompModal from './CompModal'
import FileSaver from 'file-saver'
import moment from 'moment-timezone'

const CompsScreen = () => {
  const [fromDate, setFromDate] = useState(setHours(setMinutes(setSeconds(startOfDay(new Date()), 0), 0), 6))
  const [toDate, setToDate] = useState(setHours(setMinutes(setSeconds(addDays(startOfDay(new Date()), 1), 59), 59), 5))
  const [comps, setComps] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [editComp, setEditComp] = useState(null)

  useEffect(() => {
    fetchComps()
  }, [fromDate, toDate])

  const fetchComps = async () => {
    try 
    {
      const response = await getComps(fromDate, toDate)
      setComps(response)
    } 
    catch (error) 
    {
      console.error('Error fetching comps', error)
    }
  }

  const handleDelete = async (id) => {
    try 
    {
      await deleteComp(id)
      fetchComps()
    } 
    catch (error) 
    {
      console.error('Error deleting comp', error)
    }
  }

  const handleEdit = (comp) => {
    setEditComp(comp)
    setModalOpen(true)
  }

  const handleAdd = () => {
    setEditComp(null)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    fetchComps()
  }

  const handleExport = async () => {
    try 
    {
      const response = await compsExport(fromDate, toDate)
      FileSaver.saveAs(response, 'comps.xlsx')
    } 
    catch (error) 
    {
      console.error('Error exporting comps', error)
    }
  }

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
        <Typography variant="h4">Comps Logged</Typography>
        <Box>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAdd} sx={{ mr: 2 }}>
            Add
          </Button>
          <Button variant="contained" color="secondary" onClick={handleExport}>
            Export
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <DateTimePicker
          label="From Date"
          value={fromDate}
          onChange={(date) => setFromDate(date)}
          renderInput={(props) => <TextField {...props} />}
        />
        <DateTimePicker
          label="To Date"
          value={toDate}
          onChange={(date) => setToDate(date)}
          renderInput={(props) => <TextField {...props} />}
        />
      </Box>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member</TableCell>
              <TableCell>Site</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Authorised</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>DateTime</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comps.map((comp) => (
              <TableRow key={comp._id}>
                <TableCell>{comp.member}</TableCell>
                <TableCell>{comp.site}</TableCell>
                <TableCell>{comp.type}</TableCell>
                <TableCell>{comp.amount}</TableCell>
                <TableCell>{comp.authorised}</TableCell>
                <TableCell>{comp.reason}</TableCell>
                <TableCell>{comp.comments}</TableCell>
                <TableCell>{moment(comp.dateTime).tz(userTimezone).format('YYYY-MM-DD HH:mm')}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(comp)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(comp._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CompModal open={modalOpen} onClose={handleCloseModal} initialData={editComp} />
    </Container>
  )
}

export default CompsScreen

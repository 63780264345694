import React, { useState, useEffect } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { getAllMedia, deleteMedia } from '../../API'

const MediaList = () => {
    const [media, setMedia] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchMedia = async () => {
        try {
            const response = await getAllMedia()
            if (Array.isArray(response)) {
                setMedia(response)
            } else {
                console.error('Expected an array, received:', response)
                setMedia([])
            }
        } catch (error) {
            console.error('Error fetching media:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchMedia()
    }, [])

    const handleDelete = async (id) => {
        try {
            await deleteMedia(id)
            setMedia(media.filter(mediaItem => mediaItem._id !== id))
        } catch (error) {
            console.error('Error deleting media:', error)
        }
    }

    const renderPreview = (mediaItem) => {
        if (mediaItem.type === 'image') {
            return <img src={mediaItem.url} alt="media preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        } else if (mediaItem.type === 'video') {
            return <video src={mediaItem.url} style={{ maxWidth: '300px', maxHeight: '300px' }} controls />
        } else if (mediaItem.type === 'animated') {
            return <img src={mediaItem.url} alt="animated preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        } else {
            return <span>Unknown Media Type</span>
        }
    }

    if (loading) {
        return <Typography variant="h6">Loading media...</Typography>
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Media
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/media/create"
                sx={{ mb: 3 }}
            >
                Add Media
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Preview</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(media) && media.length > 0 ? (
                            media.map((mediaItem) => (
                                <TableRow key={mediaItem._id}>
                                    <TableCell>{renderPreview(mediaItem)}</TableCell>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={`/media/edit/${mediaItem._id}`}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(mediaItem._id)}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{ ml: 2 }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No media found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default MediaList
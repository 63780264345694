import React from 'react'
import { Modal, Box } from '@mui/material'
import AddComp from './AddComp'

const CompModal = ({ open, onClose, initialData }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <AddComp initialData={initialData} onClose={onClose} />
      </Box>
    </Modal>
  )
}

export default CompModal

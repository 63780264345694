import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const Loader = ({ open }) => 
{
  if (!open) return null

  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999
    }}>
      <CircularProgress />
    </Box>
  )
}

export default Loader

import React, { useState, useEffect } from 'react'
import { Box, TextField, Button, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material'
import MemberSearchModal from './MemberSearchModal'
import { addComp, updateComp } from '../API'
import Loader from './Loader'
import Notification from './Notification'
import moment from 'moment-timezone'

const reasons = [
  "Daily DOL", "Weekly DOL", "Monthly DOL", "Turnover Achievement", "WeeklyScore Award",
  "Service Recovery", "Birthday 🎂", "Casino Gift 🎁 Offer", "Discretionary", "Other"
]

const AddComp = ({ initialData, onClose }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [formData, setFormData] = useState({
    member: { name: 'Please Select A Member' },
    type: '',
    amount: '',
    authorised: '',
    reason: '',
    comments: '',
    dateTime: ''
  })

  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' })

  useEffect(() => {
    const formattedDateTime = moment(initialData ? initialData.dateTime : Date.now())
      .tz(userTimezone)
      .format("YYYY-MM-DDTHH:mm")
    setFormData((prevData) => ({ ...prevData, ...(initialData || {}), dateTime: formattedDateTime }))
  }, [initialData, userTimezone])
  
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const clearForm = () => {
    setFormData({
      member: {},
      type: '',
      amount: '',
      authorised: '',
      reason: '',
      comments: '',
      dateTime: ''
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {

      const formDataWithUtcDateTime = {
        ...formData,
        dateTime: moment(formData.dateTime).tz(userTimezone, true).utc().format()
      }
      
      initialData ? await updateComp(initialData._id, formDataWithUtcDateTime) : await addComp(formDataWithUtcDateTime)

      console.log('Form submitted successfully')
      clearForm()
      setNotification({ open: true, message: 'Record Saved', severity: 'success' })
      onClose()
    } 
    catch (error) 
    {
      console.error('Error submitting form:', error)
      setNotification({ open: true, message: error.message, severity: 'error' })
    } 
    finally 
    {
      setLoading(false)
    }
  }

  const handleMemberSelect = (member) => {
    setFormData((prevData) => ({ ...prevData, member: { id: member.playerId, name: `${member.firstName} ${member.lastName} ${member.lastName2}` } }))
    setSearchModalOpen(false)
  }

  return (
    <>
      <Loader open={loading} />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4">{initialData ? 'Edit Comp' : 'Add Comp'}</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={() => setSearchModalOpen(true)}>Select Member</Button>
          <TextField
            label="Selected Member"
            name="member"
            value={formData.member.name}
            fullWidth
            required
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControl fullWidth required margin="normal">
            <InputLabel>Select Type</InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleChange}
            >
              <MenuItem value="NN">NN</MenuItem>
              <MenuItem value="Freeplay">Freeplay</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Amount"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            label="Who Authorised"
            name="authorised"
            value={formData.authorised}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
          />
          <FormControl fullWidth required margin="normal">
            <InputLabel>Reason Type</InputLabel>
            <Select
              name="reason"
              value={formData.reason}
              onChange={handleChange}
            >
              {reasons.map(reason => (
                <MenuItem key={reason} value={reason}>{reason}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            multiline
            rows={4}
          />
          <TextField
            label="Date/Time"
            name="dateTime"
            type="datetime-local"
            value={formData.dateTime}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>Submit</Button>
        </Box>
        <MemberSearchModal
          open={searchModalOpen}
          onClose={() => setSearchModalOpen(false)}
          onSelect={handleMemberSelect}
        />
      </Box>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={handleCloseNotification}
      />
    </>
  )
}

export default AddComp

import React, { useState, useEffect } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { getCampaigns, deleteCampaign } from '../../API' // Use the new API functions

const CampaignList = () => {
    const [campaigns, setCampaigns] = useState([]) // Initialize campaigns as an array
    const [loading, setLoading] = useState(true) // Optional loading state

    // Fetch campaigns using the new getCampaigns function
    const fetchCampaigns = async () => {
        try {
            const data = await getCampaigns()
            // Ensure the response is an array
            if (Array.isArray(data)) {
                setCampaigns(data)
            } else {
                console.error('Expected an array, received:', data)
                setCampaigns([]) // Set empty array if response is not valid
            }
        } catch (error) {
            console.error('Error fetching campaigns:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchCampaigns()
    }, [])

    // Handle delete operation using the new deleteCampaign function
    const handleDelete = async (id) => {
        try {
            await deleteCampaign(id)
            setCampaigns(campaigns.filter(campaign => campaign._id !== id))
        } catch (error) {
            console.error('Error deleting campaign:', error)
        }
    }

    if (loading) {
        return <Typography variant="h6">Loading campaigns...</Typography>
    }

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                Campaigns
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/campaigns/create"
                sx={{ mb: 3 }}
            >
                Create Campaign
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Game Type</TableCell>
                            <TableCell>Membership Tier</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(campaigns) && campaigns.length > 0 ? (
                            campaigns.map((campaign) => (
                                <TableRow key={campaign._id}>
                                    <TableCell>{campaign.name}</TableCell>
                                    <TableCell>{campaign.gameType}</TableCell>
                                    <TableCell>{campaign.membershipTier}</TableCell>
                                    <TableCell>{new Date(campaign.startDate).toLocaleDateString()}</TableCell>
                                    <TableCell>{new Date(campaign.endDate).toLocaleDateString()}</TableCell>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={`/campaigns/edit/${campaign._id}`}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(campaign._id)}
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            sx={{ ml: 2 }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No campaigns found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default CampaignList
import React, { useState, useEffect } from 'react'
import { Container, Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { addDays, setHours, setMinutes, setSeconds, startOfDay } from 'date-fns'
import { getTips, deleteTip, tipsExport } from '../API'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import TipModal from './TipModal'
import FileSaver from 'file-saver'
import moment from 'moment-timezone'

const TipsScreen = () => {
    const [fromDate, setFromDate] = useState(setHours(setMinutes(setSeconds(startOfDay(new Date()), 0), 0), 6))
    const [toDate, setToDate] = useState(setHours(setMinutes(setSeconds(addDays(startOfDay(new Date()), 1), 59), 59), 5))
    const [tips, setTips] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [editTip, setEditTip] = useState(null)

    useEffect(() => {
        fetchTips()
    }, [fromDate, toDate])

    const fetchTips = async () => {
        try 
        {
            const response = await getTips(fromDate, toDate)
            setTips(response)
        } 
        catch (error) 
        {
            console.error('Error fetching tips', error)
        }
    }

    const handleDelete = async (id) => {
        try 
        {
            await deleteTip(id)
            fetchTips()
        } 
        catch (error) 
        {
            console.error('Error deleting tip', error)
        }
    }

    const handleEdit = (tip) => {
        setEditTip(tip)
        setModalOpen(true)
    }

    const handleAdd = () => {
        setEditTip(null)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        fetchTips()
    }

    const handleExport = async () => {
        try 
        {
            const response = await tipsExport(fromDate, toDate)
            FileSaver.saveAs(response, 'tips.xlsx')
        } 
        catch (error) 
        {
            console.error('Error exporting tips', error)
        }
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                <Typography variant="h4">Tips Logged</Typography>
                <Box>
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAdd} sx={{ mr: 2 }}>
                        Add
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleExport}>
                        Export
                    </Button>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4}>
                <DateTimePicker
                    label="From Date"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                    renderInput={(props) => <TextField {...props} />}
                />
                <DateTimePicker
                    label="To Date"
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    renderInput={(props) => <TextField {...props} />}
                />
            </Box>
            <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Member</TableCell>
                            <TableCell>Site</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>DateTime</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tips.map((tip) => (
                            <TableRow key={tip._id}>
                                <TableCell>{tip.member}</TableCell>
                                <TableCell>{tip.site}</TableCell>
                                <TableCell>{tip.type}</TableCell>
                                <TableCell>{tip.currency}</TableCell>
                                <TableCell>{tip.amount}</TableCell>
                                <TableCell>{moment(tip.dateTime).tz(userTimezone).format('YYYY-MM-DD HH:mm')}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(tip)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(tip._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TipModal open={modalOpen} onClose={handleCloseModal} initialData={editTip} />
        </Container>
    )
}

export default TipsScreen

import React, { useState } from 'react'
import { Box, Button, Typography} from '@mui/material'
import MemberSearchModal from './MemberSearchModal'
import { linkMember } from '../API'
import Loader from './Loader'
import QRCode from 'qrcode.react'
import Notification from './Notification'

const LinkApp = () => {
  const [selectedMember, setSelectedMember] = useState({})
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deepLinkUrl, setDeepLinkUrl] = useState('')
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' })

  const handleCloseNotification = () => 
  {
    setNotification({ ...notification, open: false })
  }

  const handleMemberSelect = async (member) => 
  {
    setLoading(true) // Start loading
    try 
    {
      const deepLinkUrl = await linkMember(member)
      setDeepLinkUrl(deepLinkUrl)
      setSelectedMember(member)
      setSearchModalOpen(false)
    } 
    catch (error) 
    {
      console.error('Error fetching deep link URL', error)
      setNotification({ open: true, message: 'Error fetching deep link URL', severity: 'error' })
    } 
    finally 
    {
      setLoading(false) // Stop loading
    }
  }

  return (
    <>
      <Loader open={loading} />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4">Link Members App</Typography>
        <Button variant="contained" color="primary" onClick={() => setSearchModalOpen(true)}>Select Member</Button>
        <MemberSearchModal
          open={searchModalOpen}
          onClose={() => setSearchModalOpen(false)}
          onSelect={handleMemberSelect}
        />
        {selectedMember && deepLinkUrl && (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h6">Scan this QR code to link membership</Typography>
            <QRCode value={deepLinkUrl} size={256} />
          </Box>
        )}
      </Box>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={handleCloseNotification}
      />
    </>
  )
}

export default LinkApp

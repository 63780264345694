import axios from 'axios'
import { jwtDecode } from "jwt-decode";

const apiBaseUrl = `https://www.infinitycasino.tech`
const compsApiUrl = `${apiBaseUrl}/cashdesk-api/v1/comps`
const tipsApiUrl = `${apiBaseUrl}/cashdesk-api/v1/tips`
const linkMemberApiUrl = `${apiBaseUrl}/cashdesk-api/v1/members/link-member`
const loginApiUrl = `${apiBaseUrl}/auth-api/v1/login`
const registerApiUrl = `${apiBaseUrl}/auth-api/v1/register`
const accountApiUrl = `${apiBaseUrl}/cashdesk-api/v1/accounts`
const searchApiUrl = `${apiBaseUrl}/cashdesk-api/v1/members/search`
const membersApiUrl = `${apiBaseUrl}/members-api/v1/members`
const campaignsApiUrl = `${apiBaseUrl}/members-api/v1/campaigns`
const mediaApiUrl = `${apiBaseUrl}/members-api/v1/media`
const eventsApiUrl = `${apiBaseUrl}/members-api/v1/events`
const categoriesApiUrl = `${apiBaseUrl}/members-api/v1/categories`
const gameResultsApiUrl = `${apiBaseUrl}/members-api/v1/gameresults`;

const login = async (username, password) => {
    try 
    {
        const response = await axios.post(loginApiUrl, { username, password })
        const token = response.data.token

        // Decode the JWT to get the userId (assuming it is in the JWT payload)
        const decodedToken = jwtDecode(token)
        const userId = decodedToken.id

        //get the userId from the token
        const accountResponse = await axios.get(`${accountApiUrl}/account/${userId}`, {
        headers: { Authorization: `Bearer ${token}`}
        })

        localStorage.setItem('site', accountResponse.data.site)  // Save site information separately if needed

        return token

    } 
    catch (error) 
    {
        console.error('Error logging in', error)
        throw error
    }
}

const memberSearch = async (query) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(searchApiUrl, {
            params: { query },
            headers: { Authorization: `Bearer ${token}`, site: site }
        })

        return response.data
    } 
    catch (error) 
    {
        console.error('Error performing search', error)
        throw error
    }
}

const linkMember = async (member) => {
    try 
    {
    const token = localStorage.getItem('token')
    const site = localStorage.getItem('site')

    const response = await axios.get(linkMemberApiUrl, {
        params: { playerId: member.playerId, dob: member.dateOfBirth },
        headers: { Authorization: `Bearer ${token}`, site: site }
    })

    return response.data.deepLinkUrl
    } 
    catch (error) 
    {
    console.error('Error fetching deep link URL', error)
    throw error
    } 
}

// Fetch active members
const getActiveMembers = async () => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')
        const response = await axios.get(`${membersApiUrl}/active`, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
        return response.data
    } catch (error) {
        console.error('Error fetching active members:', error)
        throw error
    }
}

// Search members by username, email, or other criteria
const searchMembers = async (query) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')
        const response = await axios.get(`${membersApiUrl}/search?query=${query}`, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
        return response.data
    } catch (error) {
        console.error('Error searching members:', error)
        throw error
    }
}

// Send a password reset email to a member
const sendPasswordReset = async (memberId) => {
    try {
      const token = localStorage.getItem('token')
      const site = localStorage.getItem('site')
  
      const response = await axios.post(`${membersApiUrl}/${memberId}/password-reset`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          site: site
        }
      })
  
      return response.data
    } catch (error) {
      console.error('Error sending password reset:', error)
      throw error
    }
  }

// Toggle member status (enable or disable)
const toggleMemberStatus = async (memberId, isActive) => {
    try {
      const token = localStorage.getItem('token')
      const site = localStorage.getItem('site')
      const action = isActive ? 'disable' : 'enable'
  
      const response = await axios.put(`${membersApiUrl}/${memberId}/${action}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          site: site
        }
      })
  
      return response.data
    } catch (error) {
      console.error('Error toggling member status:', error)
      throw error
    }
  }

// Get member by ID
const getMemberById = async (memberId) => {
    try {
      const token = localStorage.getItem('token');
      const site = localStorage.getItem('site');
  
      const response = await axios.get(`${membersApiUrl}/${memberId}`, {
        headers: { Authorization: `Bearer ${token}`, site: site },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching member by ID:', error);
      throw error;
    }
  };
  
  // Get campaigns that include the member
const getCampaignsByMemberId = async (memberId) => {
    try {
      const token = localStorage.getItem('token');
      const site = localStorage.getItem('site');
  
      const response = await axios.get(`${campaignsApiUrl}/member/${memberId}`, {
        headers: { Authorization: `Bearer ${token}`, site: site },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching campaigns for member:', error);
      throw error;
    }
  };
  
  // Get game results by member ID
const getGameResultsByMemberId = async (memberId) => {
    try {
      const token = localStorage.getItem('token');
      const site = localStorage.getItem('site');
  
      const response = await axios.get(`${gameResultsApiUrl}`, {
        params: { memberId },
        headers: { Authorization: `Bearer ${token}`, site: site },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error fetching game results for member:', error);
      throw error;
    }
  };

const getComps = async (from, to) => {
    try 
    {
      const token = localStorage.getItem('token')
      const site = localStorage.getItem('site')
      const response = await axios.get(`${compsApiUrl}/all`, {
        params: {
          fromDate: from.toISOString(),
          toDate: to.toISOString()
        },
        headers: {
          Authorization: `Bearer ${token}`,
          site: site 
        }
      })
      return response.data
    } 
    catch (error) 
    {
      console.error('Error fetching comps', error)
      throw(error)
    }
}

const updateComp = async (originalId, formDataWithUtcDateTime) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.put(`${compsApiUrl}/${originalId}`, formDataWithUtcDateTime, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
    } 
    catch (error) 
    {
      console.error('Error submitting form:', error)
      throw error
    } 
}

const addComp = async (formDataWithUtcDateTime) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.post(compsApiUrl, formDataWithUtcDateTime, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
    } 
    catch (error) 
    {
        console.error('Error submitting form:', error)
        throw error
    } 
}

const deleteComp = async (id) => {
    try 
    {
      const token = localStorage.getItem('token')
      const site = localStorage.getItem('site')
      await axios.delete(`${compsApiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          site: site 
        }
      })
    } 
    catch (error) 
    {
      console.error('Error deleting comp', error)
      throw(error)
    }
}

const compsExport = async (from, to) => {
    try 
    {
      const token = localStorage.getItem('token')
      const site = localStorage.getItem('site')
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const response = await axios.get(`${compsApiUrl}/export`, {
        params: {
          fromDate: from.toISOString(),
          toDate: to.toISOString(),
          timezone: timezone
        },
        headers: {
          Authorization: `Bearer ${token}`,
          site: site 
        },
        responseType: 'blob'
      })

      return response.data
    } 
    catch (error) 
    {
      console.error('Error exporting comps', error)
      throw error
    }
  }

const getTips = async (from, to) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')
        const response = await axios.get(`${tipsApiUrl}/all`, {
            params: {
                fromDate: from.toISOString(),
                toDate: to.toISOString()
            },
            headers: {
                Authorization: `Bearer ${token}`,
                site: site 
            }
        })

        return response.data
    } 
    catch (error) 
    {
        console.error('Error fetching tips', error)
        throw error
    }
}

const updateTip = async (originalId, formDataWithUtcDateTime) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.put(`${tipsApiUrl}/${originalId}`, formDataWithUtcDateTime, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
    } 
    catch (error) 
    {
      console.error('Error submitting form:', error)
      throw error
    } 
}

const addTip = async (formDataWithUtcDateTime) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.post(tipsApiUrl, formDataWithUtcDateTime, {
            headers: { Authorization: `Bearer ${token}`, site: site }
            })
    } 
    catch (error) 
    {
        console.error('Error submitting form:', error)
        throw error
    } 
}

const deleteTip = async (id) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')
        await axios.delete(`${tipsApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site 
            }
        })
    } 
    catch (error) 
    {
        console.error('Error deleting tip', error)
        throw error
    }
}

const tipsExport = async (from, to) => {
    try 
    {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const response = await axios.get(`${tipsApiUrl}/export`, {
            params: {
                fromDate: from.toISOString(),
                toDate: to.toISOString(),
                timezone: timezone
            },
            headers: {
                Authorization: `Bearer ${token}`,
                site: site 
            },
            responseType: 'blob'
        })

        return response.data
    } 
    catch (error) 
    {
        console.error('Error exporting tips', error)
        throw error
    }
}

// Get a single campaign by ID
const getCampaign = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${campaignsApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching campaign:', error)
        throw error
    }
}

// Create a new campaign
const createCampaign = async (payload) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.post(campaignsApiUrl, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })
    } catch (error) {
        console.error('Error creating campaign:', error)
        throw error
    }
}

// Update an existing campaign
const updateCampaign = async (id, payload) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.put(`${campaignsApiUrl}/${id}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })
    } catch (error) {
        console.error('Error updating campaign:', error)
        throw error
    }
}

// Get all campaigns
const getCampaigns = async () => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(campaignsApiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching campaigns', error)
        throw error
    }
}

// Delete a campaign by ID
const deleteCampaign = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.delete(`${campaignsApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })
    } catch (error) {
        console.error('Error deleting campaign', error)
        throw error
    }
}

const getEvents = async () => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${eventsApiUrl}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching events:', error)
        throw error
    }
}

// Fetch an event by ID
const getEvent = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${eventsApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching event:', error)
        throw error
    }
}

// Save an event (create or update)
const saveEvent = async (id, eventData) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        if (id) {
            await axios.put(`${eventsApiUrl}/${id}`, eventData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    site: site
                }
            })
        } else {
            await axios.post(eventsApiUrl, eventData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    site: site
                }
            })
        }
    } catch (error) {
        console.error('Error saving event:', error)
        throw error
    }
}

const deleteEvent = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.delete(`${eventsApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })
    } catch (error) {
        console.error('Error deleting event:', error)
        throw error
    }
}

const getAllMedia = async () => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${mediaApiUrl}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching media:', error)
        throw error
    }
}

// Fetch media by ID
const getMedia = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${mediaApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching media:', error)
        throw error
    }
}

// Save media (create or update)
const saveMedia = async (id, mediaData) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        if (id) {
            await axios.put(`${mediaApiUrl}/${id}`, mediaData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    site: site
                }
            })
        } else {
            await axios.post(mediaApiUrl, mediaData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    site: site
                }
            })
        }
    } catch (error) {
        console.error('Error saving media:', error)
        throw error
    }
}

const deleteMedia = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        await axios.delete(`${mediaApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })
    } catch (error) {
        console.error('Error deleting media:', error)
        throw error
    }
}

// Fetch categories
const getCategories = async () => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(categoriesApiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching categories:', error)
        throw error
    }
}

// Fetch a category by ID
const getCategoryById = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.get(`${categoriesApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error fetching category:', error)
        throw error
    }
}

// Create a new category
const createCategory = async (categoryData) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.post(categoriesApiUrl, categoryData, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error creating category:', error)
        throw error
    }
}

// Update an existing category
const updateCategory = async (id, categoryData) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.put(`${categoriesApiUrl}/${id}`, categoryData, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error updating category:', error)
        throw error
    }
}

// Delete a category
const deleteCategory = async (id) => {
    try {
        const token = localStorage.getItem('token')
        const site = localStorage.getItem('site')

        const response = await axios.delete(`${categoriesApiUrl}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                site: site
            }
        })

        return response.data
    } catch (error) {
        console.error('Error deleting category:', error)
        throw error
    }
}

export { 
    login, 
    memberSearch, 
    linkMember,
    searchMembers,
    getActiveMembers,
    getComps, 
    updateComp, 
    addComp, 
    deleteComp, 
    compsExport, 
    getTips, 
    updateTip, 
    addTip, 
    deleteTip, 
    tipsExport,
    getCampaign,
    createCampaign,
    updateCampaign,
    getCampaigns,
    deleteCampaign,
    getEvents,
    getEvent, 
    saveEvent,
    deleteEvent,
    getCategories,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory,
    getAllMedia,
    getMedia, 
    saveMedia,
    deleteMedia,
    sendPasswordReset,
    toggleMemberStatus,
    getMemberById,
    getCampaignsByMemberId,
    getGameResultsByMemberId
}
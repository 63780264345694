import React, { useState, useEffect } from 'react'
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Grid,
    Box,
    FormControlLabel,
    Checkbox
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { getEvent, saveEvent, getAllMedia, getCategories } from '../../API' // Import the API functions

const EventForm = () => {
    const [eventData, setEventData] = useState({
        title: '',
        description: '',
        startDate: null,
        endDate: null,
        media: [],
        primaryMedia: '',
        category: '',
        enabled: true
    })
    const [mediaOptions, setMediaOptions] = useState([])
    const [categories, setCategories] = useState([])

    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const event = await getEvent(id)
                    setEventData({
                        ...event,
                        startDate: moment(event.startDate),
                        endDate: moment(event.endDate)
                    })
                } catch (error) {
                    console.error('Error fetching event:', error)
                }
            }
            await fetchMediaOptions()
            await fetchCategories()
        }

        fetchData()
    }, [id])

    const fetchMediaOptions = async () => {
        try {
            const media = await getAllMedia()
            setMediaOptions(media)
        } catch (error) {
            console.error('Error fetching media options:', error)
        }
    }

    const fetchCategories = async () => {
        try {
            const categories = await getCategories()
            setCategories(categories)
        } catch (error) {
            console.error('Error fetching categories:', error)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setEventData({
            ...eventData,
            [name]: value
        })
    }

    const handleDateChange = (name, date) => {
        setEventData({
            ...eventData,
            [name]: date
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const payload = {
            ...eventData,
            startDate: eventData.startDate.toISOString(),
            endDate: eventData.endDate.toISOString()
        }

        try {
            await saveEvent(id, payload)
            navigate('/events')
        } catch (error) {
            console.error('Error saving event:', error)
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h5">{id ? 'Edit Event' : 'Create Event'}</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="title"
                        label="Event Title"
                        fullWidth
                        value={eventData.title}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Start Date"
                        value={eventData.startDate}
                        onChange={(date) => handleDateChange('startDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="End Date"
                        value={eventData.endDate}
                        onChange={(date) => handleDateChange('endDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        name="description"
                        label="Event Description"
                        fullWidth
                        value={eventData.description}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                            name="category"
                            value={eventData.category}
                            onChange={handleChange}
                            required
                        >
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Primary Media</InputLabel>
                        <Select
                            name="primaryMedia"
                            value={eventData.primaryMedia}
                            onChange={handleChange}
                        >
                            {mediaOptions.map((media) => (
                                <MenuItem key={media._id} value={media._id}>
                                    {media.type} - {media.url}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Media</InputLabel>
                        <Select
                            name="media"
                            multiple
                            value={eventData.media}
                            onChange={handleChange}
                            renderValue={(selected) =>
                                selected.map((id) => {
                                    const media = mediaOptions.find((m) => m._id === id)
                                    return media ? `${media.type} - ${media.url}` : ''
                                }).join(', ')
                            }
                        >
                            {mediaOptions.map((media) => (
                                <MenuItem key={media._id} value={media._id}>
                                    {media.type} - {media.url}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="enabled"
                                checked={eventData.enabled}
                                onChange={(e) => setEventData({
                                    ...eventData,
                                    enabled: e.target.checked
                                })}
                            />
                        }
                        label="Enabled"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update Event' : 'Create Event'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EventForm
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Login from './components/Login'
import Home from './components/Home'
import CompsScreen from './components/CompsScreen'
import TipsScreen from './components/TipsScreen'
import LinkApp from './components/LinkApp'
import Sidebar from './components/Sidebar'
import CampaignList from './components/Campaigns/CampaignList'
import CampaignForm from './components/Campaigns/CampaignForm'
import EventForm from './components/Events/EventForm'
import EventList from './components/Events/EventList'
import CategoryList from './components/Categories/CategoryList'
import CategoryForm from './components/Categories/CategoryForm'
import MediaList from './components/Media/MediaList'
import MediaForm from './components/Media/MediaForm'
import MembersList from './components/Members/MembersList'
import MemberProfile from './components/Members/MemberProfile';
import { AuthProvider, useAuth } from './context/AuthContext'
import darkTheme from './theme'

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <MainContent />
          </ThemeProvider>
        </LocalizationProvider>
      </Router>
    </AuthProvider>
  )
}

const MainContent = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {isAuthenticated && <Sidebar />}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={isAuthenticated ? <Home /> : <Login />} />
          <Route path="/comps" element={isAuthenticated ? <CompsScreen /> : <Login />} />
          <Route path="/tips" element={isAuthenticated ? <TipsScreen /> : <Login />} />
          <Route path="/link" element={isAuthenticated ? <LinkApp /> : <Login />} />
          {/* Campaign management routes */}
          <Route path="/campaigns" element={isAuthenticated ? <CampaignList /> : <Login />} />
          <Route path="/campaigns/create" element={isAuthenticated ? <CampaignForm /> : <Login />} />
          <Route path="/campaigns/edit/:id" element={isAuthenticated ? <CampaignForm /> : <Login />} />
          {/* Event management routes */}
          <Route path="/events" element={isAuthenticated ? <EventList /> : <Login />} />
          <Route path="/events/create" element={isAuthenticated ? <EventForm /> : <Login />} />
          <Route path="/events/edit/:id" element={isAuthenticated ? <EventForm /> : <Login />} />
          {/* Category management routes */}
          <Route path="/categories" element={isAuthenticated ? <CategoryList /> : <Login />} />
          <Route path="/categories/create" element={isAuthenticated ? <CategoryForm /> : <Login />} />
          <Route path="/categories/edit/:id" element={isAuthenticated ? <CategoryForm /> : <Login />} />
          {/* Media management routes */}
          <Route path="/media" element={isAuthenticated ? <MediaList /> : <Login />} />
          <Route path="/media/create" element={isAuthenticated ? <MediaForm /> : <Login />} />
          <Route path="/media/edit/:id" element={isAuthenticated ? <MediaForm /> : <Login />} />
          {/* Members management routes */}
          <Route path="/members" element={isAuthenticated ? <MembersList /> : <Login />} />
          <Route path="/members/:id" element={isAuthenticated ? <MemberProfile /> : <Login />} />
        </Routes>
      </Box>
    </Box>
  )
}

export default App
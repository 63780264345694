import React, { useState } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LockResetIcon from '@mui/icons-material/LockReset'
import BlockIcon from '@mui/icons-material/Block'
import { sendPasswordReset, toggleMemberStatus } from '../../API'

const MemberActions = ({ member, onMemberUpdate }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [actionType, setActionType] = useState('')
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleActionClick = (type) => {
    setActionType(type)
    setConfirmOpen(true)
    handleMenuClose()
  }

  const handleConfirm = async () => {
    try {
      if (actionType === 'passwordReset') {
        await sendPasswordReset(member._id)
        alert('Password reset sent successfully')
      } else if (actionType === 'toggleStatus') {
        const updatedMember = await toggleMemberStatus(member._id, member.isActive)
        alert(`Member ${updatedMember.isActive ? 'enabled' : 'disabled'} successfully`)
        onMemberUpdate(updatedMember)
      }
    } catch (error) {
      console.error('Error performing action:', error)
      alert('An error occurred while performing the action.')
    } finally {
      setConfirmOpen(false)
    }
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls={`member-actions-menu-${member.userId}`}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`member-actions-menu-${member.userId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleActionClick('passwordReset')}>
          <ListItemIcon>
            <LockResetIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Send Password Reset" />
        </MenuItem>
        <MenuItem onClick={() => handleActionClick('toggleStatus')}>
          <ListItemIcon>
            <BlockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={member.isActive ? 'Disable Member' : 'Enable Member'} />
        </MenuItem>
        {/* Add more actions here */}
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          {actionType === 'passwordReset' && (
            <>Are you sure you want to send a password reset to {member.username}?</>
          )}
          {actionType === 'toggleStatus' && (
            <>
              Are you sure you want to {member.isActive ? 'disable' : 'enable'} {member.username}?
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MemberActions
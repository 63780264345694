import React, { useState, useEffect } from 'react'
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid,
    Box
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { getCampaign, updateCampaign, createCampaign } from '../../API'

const CampaignForm = () => {
    const [campaignData, setCampaignData] = useState({
        name: '',
        gameType: '',
        membershipTier: '',
        startDate: null,
        endDate: null,
        prizeBudgetPerCustomer: '',
        gameFrequency: '',
        frequencyInterval: '',
        enabled: true,
        maxPlaysPerCustomer: '',
        description: ''
    })

    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        const fetchCampaignData = async () => {
            if (id) {
                try {
                    const data = await getCampaign(id) // Fetch campaign data using the API
                    setCampaignData({
                        ...data,
                        startDate: moment(data.startDate),
                        endDate: moment(data.endDate)
                    })
                } catch (error) {
                    console.error('Error fetching campaign:', error)
                }
            }
        }

        fetchCampaignData()
    }, [id])

    const handleChange = (e) => {
        const { name, value } = e.target
        setCampaignData({
            ...campaignData,
            [name]: value
        })
    }

    const handleDateChange = (name, date) => {
        setCampaignData({
            ...campaignData,
            [name]: date
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const payload = {
            ...campaignData,
            startDate: campaignData.startDate.toISOString(),
            endDate: campaignData.endDate.toISOString()
        }

        try {
            if (id) {
                await updateCampaign(id, payload) // Use the updateCampaign method from API
            } else {
                await createCampaign(payload) // Use the createCampaign method from API
            }
            navigate('/campaigns') // Redirect to the campaigns list
        } catch (error) {
            console.error('Error saving campaign:', error)
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h5">{id ? 'Edit Campaign' : 'Create Campaign'}</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="name"
                        label="Campaign Name"
                        fullWidth
                        value={campaignData.name}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Game Type</InputLabel>
                        <Select
                            name="gameType"
                            value={campaignData.gameType}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="spin">Spin</MenuItem>
                            <MenuItem value="dice">Dice</MenuItem>
                            <MenuItem value="mysteryBox">Mystery Box</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Membership Tier</InputLabel>
                        <Select
                            name="membershipTier"
                            value={campaignData.membershipTier}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="prestige">Prestige</MenuItem>
                            <MenuItem value="gold">Gold</MenuItem>
                            <MenuItem value="diamond">Diamond</MenuItem>
                            <MenuItem value="black">Black</MenuItem>
                            <MenuItem value="mip">MIP</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="Start Date"
                        value={campaignData.startDate}
                        onChange={(date) => handleDateChange('startDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DatePicker
                        label="End Date"
                        value={campaignData.endDate}
                        onChange={(date) => handleDateChange('endDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth required />}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        name="prizeBudgetPerCustomer"
                        label="Prize Budget Per Customer"
                        type="number"
                        fullWidth
                        value={campaignData.prizeBudgetPerCustomer}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Game Frequency</InputLabel>
                        <Select
                            name="gameFrequency"
                            value={campaignData.gameFrequency}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="weekly">Weekly</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {campaignData.gameFrequency === 'custom' && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="frequencyInterval"
                            label="Frequency Interval (hours)"
                            type="number"
                            fullWidth
                            value={campaignData.frequencyInterval}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <TextField
                        name="maxPlaysPerCustomer"
                        label="Max Plays Per Customer"
                        type="number"
                        fullWidth
                        value={campaignData.maxPlaysPerCustomer}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        fullWidth
                        value={campaignData.description}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="enabled"
                                    checked={campaignData.enabled}
                                    onChange={(e) => setCampaignData({
                                        ...campaignData,
                                        enabled: e.target.checked
                                    })}
                                />
                            }
                            label="Enabled"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update Campaign' : 'Create Campaign'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CampaignForm
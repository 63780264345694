import React, { useState, useEffect } from 'react'
import { TextField, Button, Typography, Grid, Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { createCategory, getCategoryById, updateCategory } from '../../API'

const CategoryForm = () => {
    const [categoryData, setCategoryData] = useState({
        name: '',
        iconName: ''
    })
    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        if (id) {
            const fetchCategory = async () => {
                try {
                    const data = await getCategoryById(id)
                    setCategoryData(data)
                } catch (error) {
                    console.error('Error fetching category:', error)
                }
            }
            fetchCategory()
        }
    }, [id])

    const handleChange = (e) => {
        const { name, value } = e.target
        setCategoryData({
            ...categoryData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (id) {
                await updateCategory(id, categoryData)
            } else {
                await createCategory(categoryData)
            }
            navigate('/categories')
        } catch (error) {
            console.error('Error saving category:', error)
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h5">{id ? 'Edit Category' : 'Create Category'}</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Category Name"
                        fullWidth
                        value={categoryData.name}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="iconName"
                        label="Icon Name"
                        fullWidth
                        value={categoryData.iconName}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update Category' : 'Create Category'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CategoryForm
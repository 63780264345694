import React from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import QRCodeIcon from '@mui/icons-material/QrCode'
import LogoutIcon from '@mui/icons-material/Logout'
import CampaignIcon from '@mui/icons-material/EmojiEvents' // New icon for campaigns
import EventIcon from '@mui/icons-material/Event'
import CategoryIcon from '@mui/icons-material/Category'
import MediaIcon from '@mui/icons-material/PermMedia'
import MemberIcon from '@mui/icons-material/VerifiedUser'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

const Sidebar = () => {

  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  return (
    <Drawer variant="permanent" sx={{ width: 240, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' } }}>
      <List>
        <ListItem button component={Link} to="/home">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/comps">
          <ListItemIcon><CardGiftcardIcon /></ListItemIcon>
          <ListItemText primary="Comps" />
        </ListItem>
        <ListItem button component={Link} to="/tips">
          <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
          <ListItemText primary="Tips" />
        </ListItem>
        <ListItem button component={Link} to="/link">
          <ListItemIcon><QRCodeIcon /></ListItemIcon>
          <ListItemText primary="Link App" />
        </ListItem>
        {/* New list item for viewing campaigns */}
        <ListItem button component={Link} to="/campaigns">
          <ListItemIcon><CampaignIcon /></ListItemIcon>
          <ListItemText primary="Campaigns" />
        </ListItem>
        <ListItem button component={Link} to="/events">
          <ListItemIcon><EventIcon /></ListItemIcon>
          <ListItemText primary="Events" />
        </ListItem>
        <ListItem button component={Link} to="/categories">
          <ListItemIcon><CategoryIcon /></ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
        <ListItem button component={Link} to="/media">
          <ListItemIcon><MediaIcon /></ListItemIcon>
          <ListItemText primary="Media" />
        </ListItem>
        <ListItem button component={Link} to="/members">
          <ListItemIcon><MemberIcon /></ListItemIcon>
          <ListItemText primary="Members" />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default Sidebar
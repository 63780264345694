import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Updated import
import { getActiveMembers, searchMembers } from '../../API';
import MemberActions from './MemberActions';

const MembersList = () => {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Updated hook

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const data = await getActiveMembers();
        setMembers(data);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchMembers();
  }, []);

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.trim() !== '') {
      try {
        const data = await searchMembers(query);
        setMembers(data);
      } catch (error) {
        console.error('Error searching members:', error);
      }
    } else {
      setLoading(true);
      const data = await getActiveMembers();
      setMembers(data);
      setLoading(false);
    }
  };

  const handleRowClick = (memberId) => {
    navigate(`/members/${memberId}`); // Updated navigation
  };

  if (loading) {
    return <Typography variant="h6">Loading members...</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Active Members
      </Typography>
      <TextField
        label="Search by username or email"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 3 }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* Existing columns */}
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Player ID</TableCell>
              <TableCell>Last Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow
                key={member.userId}
                onClick={() => handleRowClick(member._id)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{member.username}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>{member.fullName}</TableCell>
                <TableCell>
                  {member.dateOfBirth
                    ? new Date(member.dateOfBirth).toLocaleDateString()
                    : 'N/A'}
                </TableCell>
                <TableCell>{member.playerId || 'Not linked'}</TableCell>
                <TableCell>
                  {member.lastActive
                    ? new Date(member.lastActive).toLocaleString()
                    : 'N/A'}
                </TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <MemberActions member={member} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MembersList;
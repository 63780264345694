// src/components/Home.js

import React from 'react'
import { Typography, Box } from '@mui/material'

const Home = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4">Home</Typography>
      <Typography variant="body1">Welcome to the Infinity Web Home Page!</Typography>
    </Box>
  )
}

export default Home

import React from 'react'
import { Modal, Box } from '@mui/material'
import AddTip from './AddTip'

const TipModal = ({ open, onClose, initialData }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      }}>
        <AddTip initialData={initialData} onClose={onClose} />
      </Box>
    </Modal>
  )
}

export default TipModal

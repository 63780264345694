import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  getMemberById,
  getCampaignsByMemberId,
  getGameResultsByMemberId,
} from '../../API';

const MemberProfile = () => {
  const { id } = useParams();
  const [member, setMember] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [gameResults, setGameResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const memberData = await getMemberById(id);
        setMember(memberData);

        const memberCampaigns = await getCampaignsByMemberId(id);
        setCampaigns(memberCampaigns);

        const memberGameResults = await getGameResultsByMemberId(id);
        setGameResults(memberGameResults);
      } catch (error) {
        console.error('Error fetching member data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberData();
  }, [id]);

  if (loading) {
    return <Typography variant="h6">Loading member profile...</Typography>;
  }

  if (!member) {
    return <Typography variant="h6">Member not found</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {member.fullName}'s Profile
      </Typography>
      <Paper sx={{ padding: 2, mb: 3 }}>
        <Typography variant="h6">Personal Information</Typography>
        <Typography>Email: {member.email}</Typography>
        <Typography>Username: {member.username}</Typography>
        <Typography>
          Date of Birth:{' '}
          {member.dateOfBirth
            ? new Date(member.dateOfBirth).toLocaleDateString()
            : 'N/A'}
        </Typography>
        <Typography>Player ID: {member.playerId || 'Not linked'}</Typography>
        <Typography>
          Last Active:{' '}
          {member.lastActive
            ? new Date(member.lastActive).toLocaleString()
            : 'N/A'}
        </Typography>
      </Paper>

      <Typography variant="h5" gutterBottom>
        Active Campaigns
      </Typography>
      {/* ... existing code for campaigns */}

      <Typography variant="h5" gutterBottom>
        Previous Game Results
      </Typography>
      {gameResults.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Type</TableCell>
                <TableCell>Outcome</TableCell>
                <TableCell>Prize</TableCell>
                <TableCell>Result Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gameResults.map((result) => (
                <TableRow key={result._id}>
                  <TableCell>{result.gameEventId?.type || 'N/A'}</TableCell>
                  <TableCell>{result.outcome}</TableCell>
                  <TableCell>{result.prize?.name || 'N/A'}</TableCell>
                  <TableCell>
                    {result.resultDate
                      ? new Date(result.resultDate).toLocaleString()
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No game results found for this member.</Typography>
      )}
    </Box>
  );
};

export default MemberProfile;
import React, { useState, useEffect } from 'react'
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Grid,
    Box
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { getMedia, saveMedia } from '../../API' // Import the API methods

const MediaForm = () => {
    const [mediaData, setMediaData] = useState({
        type: '',
        url: ''
    })

    const navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const media = await getMedia(id)
                    setMediaData(media)
                } catch (error) {
                    console.error('Error fetching media:', error)
                }
            }
        }

        fetchData()
    }, [id])

    const handleChange = (e) => {
        const { name, value } = e.target
        setMediaData({
            ...mediaData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            await saveMedia(id, mediaData)
            navigate('/media') // Navigate back to the media list
        } catch (error) {
            console.error('Error saving media:', error)
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography variant="h5">{id ? 'Edit Media' : 'Create Media'}</Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                            name="type"
                            value={mediaData.type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value="image">Image</MenuItem>
                            <MenuItem value="video">Video</MenuItem>
                            <MenuItem value="animated">Animated</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        name="url"
                        label="Media URL"
                        fullWidth
                        value={mediaData.url}
                        onChange={handleChange}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        {id ? 'Update Media' : 'Create Media'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MediaForm